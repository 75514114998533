<template>
  <div class="bg-linear-pink-invert">
    <div class="mx-auto container flex flex-col items-center justify-center">
      <div>
        <img src="/logo.jpg" width="79">
      </div>
      <div class="text-black flex flex-col md:items-center f-f-l pt-3">
        <h1 class="text-1xl font-black">Аналізуй. Відслідковуй. Накопичуй.</h1>
        <div class="my-6 text-base text-color f-f-l">
          <ul class="md:flex items-center">
            <li class="md:mr-6 cursor-pointer pt-4 lg:py-0"><a href="/">Головна</a></li>
            <li class="md:mr-6 cursor-pointer pt-4 lg:py-0"><a href="/">Про проєкт</a></li>
            <li class="md:mr-6 cursor-pointer pt-4 lg:py-0"><a :href="route('plans')">Тарифи</a></li>
            <li class="md:mr-6 cursor-pointer pt-4 lg:py-0"><a :href="route('subscription.show')">Управління передплатою</a></li>
            <li class="md:mr-6 cursor-pointer pt-4 lg:py-0"><a :href="route('terms.show')">Публічна оферта</a></li>
            <li class="cursor-pointer pt-4 lg:py-0"><a :href="route('policy.show')">Політика персональних даних</a></li>
          </ul>
        </div>
        <div class="text-sm text-color mb-10 f-f-l">
          <p>© 2023 Paifolio - З будь-яких питань звертайтесь <a href="mailto:support@paifolio.com" class="text-green-600">support@paifolio.com</a></p>
        </div>
      </div>
      <div class="w-9/12 h-0.5 background-brands"></div>
    </div>
  </div>
</template>
<script setup>
</script>
